import {
  createActionGroup,
  createFeature,
  createFeatureSelector,
  createReducer,
  createSelector,
  emptyProps,
  on,
  props,
} from '@ngrx/store';
import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';
import { AndroidSharedFile } from '../services/android-share-sheet-plugin.service';

export const ShareFileWithSdActions = createActionGroup({
  source: 'shareFileWithSd',
  events: {
    'Shared Files Received': props<{
      shareFiles: AndroidSharedFile[];
    }>(),
    'All Files Info Requested': emptyProps(),
    'File Info Received': props<{ sharedFile: Update<AndroidSharedFile> }>(),
    'All Files Info Received': emptyProps(),
    'Reset State': emptyProps(),
  },
});

interface ShareFileWithSdState extends EntityState<AndroidSharedFile> {
  requestingFileInfo: boolean;
}

const adapter = createEntityAdapter<AndroidSharedFile>({
  selectId: (sharedFile: AndroidSharedFile) => sharedFile.uri,
});

const initialState: ShareFileWithSdState = adapter.getInitialState({
  requestingFileInfo: false,
});

export const shareFileWithSdFeature = createFeature({
  name: 'shareFileWithSd',
  reducer: createReducer(
    initialState,
    on(ShareFileWithSdActions.sharedFilesReceived, (state, { shareFiles }) => ({
      ...state,
      ...adapter.upsertMany(shareFiles, state),
    })),
    on(ShareFileWithSdActions.fileInfoReceived, (state, { sharedFile }) => ({
      ...state,
      ...adapter.updateOne(sharedFile, state),
    })),
    on(ShareFileWithSdActions.allFilesInfoRequested, state => ({
      ...state,
      allFileInfoRequested: true,
    })),
    on(ShareFileWithSdActions.allFilesInfoReceived, state => ({
      ...state,
      allFileInfoRequested: true,
    })),
    on(ShareFileWithSdActions.resetState, () => initialState)
  ),
});

const { selectAll } = adapter.getSelectors();

export const sharedFilesSelector =
  createFeatureSelector<ShareFileWithSdState>('shareFileWithSd');

export const sharedFilesAllSelector = createSelector(
  sharedFilesSelector,
  selectAll
);
