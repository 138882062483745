// android-share-sheet-plugin.service.ts
import { Injectable, signal } from '@angular/core';
import {
  Capacitor,
  PluginListenerHandle,
  PluginResultData,
  PluginResultError,
  registerPlugin,
} from '@capacitor/core';
import { Observable, Subject } from 'rxjs';

const _pluginName: string = 'ShareSheetPlugin';

export class AndroidSharedFile {
  uri: string = '';
  fileName: string = '';
  fileSize: string = '';
  mimeType?: string = '';
  data?: string;
}

export interface AndroidShareSheetPlugin {}
const ShareSheetPlugin = registerPlugin<AndroidShareSheetPlugin>(_pluginName);

@Injectable()
export class AndroidShareSheetPluginService {
  private readonly _shareReceived = 'shared_file_received';
  private readonly _shareReceivedMultiple = 'shared_multiple_file_received';
  private _shareHandler: PluginListenerHandle | null = null;
  private _shareHandlerMultiple: PluginListenerHandle | null = null;

  private _share$: Subject<AndroidSharedFile[]> | null = null;

  watch(): Observable<AndroidSharedFile[]> {
    if (!this._share$) {
      this._share$ = new Subject<AndroidSharedFile[]>();
    } else {
      return this._share$;
    }
    const callback = (data: PluginResultData, error?: PluginResultError) => {
      if(error) {
        console.error(error);
        this._share$?.next([]);
      }
      this._share$?.next(Object.values(data));
    }
    if (
      Capacitor.isPluginAvailable(_pluginName) &&
      typeof Capacitor.addListener !== 'undefined'
    ) {
      this._shareHandler = Capacitor.addListener(
        _pluginName,
        this._shareReceived,
        callback
      );
    }
    if (
      Capacitor.isPluginAvailable(_pluginName) &&
      typeof Capacitor.addListener !== 'undefined'
    ) {
      this._shareHandlerMultiple = Capacitor.addListener(
        _pluginName,
        this._shareReceivedMultiple,
        callback
      );
    }
    return this._share$;
  }

  async unsubscribe() {
    await this._shareHandler?.remove();
    await this._shareHandlerMultiple?.remove();
    this._share$?.complete();
    this._share$ = null;
  }
}
